import React from "react"

//Styles
import * as styles from "../styles/assistant.module.scss";

export default class extends React.Component {

    constructor(props) {
        super(props);

        this.isBrowser = typeof window !== "undefined";

        this.state = {
            messages: [
                ["Welcome to my portfolio website. Ask my AI-double anything about my experience.", "system"],
                ["I'll give it a try!", "user"]
            ],
            sessionId: "",
            chatAwaiting: false
        }

        //this.setState({tabIndexAbout: index});

    }

    submitChat(e) {

        e.preventDefault();

        let data = new FormData(e.target);


        if (this.isBrowser) {
            let inputRef = document.getElementById("chatInput");

            let message = `${inputRef.value}`;
            //console.log(inputRef.value);
            inputRef.value = "";
            inputRef.placeholder = "";

            this.setState({ chatAwaiting: true, messages: [...this.state.messages, [message, "user"]] }, () => {

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ message: message, sessionId: this.state.sessionId })
                };

                fetch('https://portfolio.zenonmilou.com/chat', requestOptions)
                    .then(response => {
                        if (!response.ok) {
                            return Promise.reject(response);
                        }
                        return response.json();
                    })
                    .then(
                        (data) => {

                            if (typeof data.message === "undefined" || typeof data.sessionId === "undefined")
                                return Promise.reject("Invalid response");

                            this.setState({ chatAwaiting: false, messages: [...this.state.messages, [data.message, "system"]], sessionId: data.sessionId })
                        }
                    )
                    .catch(error => {
                        this.setState({ chatAwaiting: false, messages: [...this.state.messages, ["Sorry, looks like this feature is down right now.", "system"]] });
                        console.log("Error in chat API");
                        console.log(error);
                    });

            });


        }

        //console.log(...data.entries());


    }


    componentDidMount() {


    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div className={`${styles.container}`}>
                <div className={`${styles.responseContainer}`}>
                    {this.state.messages.map((msg, index) => (
                        <div key={index} className={`${styles.bubbleContainer} ${(msg[1] == "system") ? styles.system : styles.user}`}>
                            <div className={`${styles.bubble}`}>{msg[0]}</div>
                        </div>
                    ))}
                    {
                        this.state.chatAwaiting ? <div className={`${styles.bubbleContainer} ${styles.systemAwaiting}`}>
                            <div className={`${styles.bubble}`}>...</div>
                        </div> : <></>
                    }
                </div>
                <div className={`${styles.inputContainer}`}>
                    <div className={`${styles.inputInnerContainer}`}>
                        <form onSubmit={e => this.submitChat(e)}>
                            <input id="chatInput" required pattern="^(?!\s*$).+" name="chatInput" type="text" className={`${styles.input}`} placeholder="e.g. What design tools do you use for..." />
                            <input type="submit" className={`${styles.askButton}`} value="Ask" />
                        </form>
                    </div>
                    <div className={styles.disclaimer}>Remember, AI doesn't always get it right. Contact me to confirm any details. <a href="https://chatwith.tools/privacy" target="_blank">Privacy policy.</a></div>
                </div>
            </div>
        )
    }
}